@import "common.scss";

.bd-quiz-header {
  &__main {   
    @include responsive-size(margin-bottom, 10);
    display: flex;
  }
  &--invisible {
    visibility: hidden;
  }

  &__logo {
    @include responsive-size(width, 158);
    @include responsive-size(height, 110);
    @include responsive-size(margin-bottom, 10);
    @include responsive-size(margin-left, 20);
    @include responsive-size(margin-right, 20);

  }

  &__objectif-background, &__remaining-time-background {
    @include responsive-size(width, 211);
    @include responsive-size(height, 102);
  }


  &__objectif, &__remaining-time {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; 
    
    &-text {
    font-family: "Fredoka One";
    @include responsive-size(font-size, 45);
    color: white;   
      position: absolute;
      user-select: none;
      @include responsive-size(padding-top, 25);
    }
  }
}
