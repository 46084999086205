@import "common.scss";

.bd-quizhome {
  &--not-ready {
    opacity: 0.3;
  }

  &__main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @include responsive-size(width, 1525);
    @include responsive-size(height, 1145);
  }

  &__content {
    @include responsive-size(width, 1000);
    @include responsive-size(height, 950);
    display: flex;
    align-items: center;
    flex-direction: column;
    @include responsive-size(margin-top, 10);
    border-radius: 5%;
    background-color: white;

    &-header {
      font-family: "Fredoka One";
      @include responsive-size(font-size, 35);
    }
  }

  &__header-image {
    @include responsive-size(width, 218);
    @include responsive-size(height, 178);
  }
  &__scores {
    position: absolute;
    @include responsive-size(width, 270);
    @include responsive-size(right, 20);
    @include responsive-size(bottom, 140);
    transform: rotate(-8deg);
  }
  &-commencer {
    width: 65%;
    @include responsive-size(margin, 15);
    @include responsive-size(width, 680);
    @include responsive-size(height, 80);
    border-color: #5a9f6d;
    background-color: white;
    color: #5a9f6d;
    @include responsive-size(border-radius, 20);
  }
  &-mode-container {
    display: flex;
    @include responsive-size(margin-top, 5);
    @include responsive-size(margin-bottom, 10);
  }
  &-mode-button {
    @include responsive-size(font-size, 28);
    text-transform: none;
    @include responsive-size(width, 340);
    @include responsive-size(height, 100);
    background-color: #dddddd;
    color: white;
    @include responsive-size(border-radius, 20);

    &--selected {     
      background-color: #5a9f6d;
      color: white;
      @include responsive-size(border-radius, 20);
    }
  }
}
