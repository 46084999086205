@import "../../../common.scss";

.bd-quiz-modal {
  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    @include responsive-size(border-radius, 50);
    @include responsive-size(padding, 60);
  }
}
