@import "common.scss";

.bd-quiz-question-choice {
  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    @include responsive-size(border-radius, 20);
    @include responsive-size(padding, 20);    
    font-family: "Fredoka One";
    position: relative;
    cursor: pointer;

    &--correct{
       background-color: #5EBBCE;
       border: 2px solid #0C6679;
       color: white;
    }
    &--incorrect{
       background-color: #9D615A;
       border: 2px solid #744848;
       color: white;
    }
    &--faded {
       opacity: 0.3;
    }
  }
  &__label {
    &--big {
      @include responsive-size(font-size, 100);
    }
  }
  &-double {
    @include responsive-size(width, 330);
    @include responsive-size(height, 263);
    @include responsive-size(font-size, 42);
    @include responsive-size(margin, 20);
    text-transform: uppercase;
  }
  &-double &__icon-container {
    position: absolute;
    @include responsive-size(top, 28);
  }

  &__icon {
    @include responsive-size(width, 60);
    @include responsive-size(height, 60);
  }

  &-multi {
    @include responsive-size(width, 871);
    @include responsive-size(height, 107);
    @include responsive-size(margin, 10);
    @include responsive-size(font-size, 31);
  }

  &-multi &__icon-container {
    position: absolute;
    @include responsive-size(right, 28);
    pointer-events: none;
  }
}
