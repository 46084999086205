@import 'common.scss';

.bd-quiz-table-item {
    &__main {
       display: flex; 
       align-items: center; 
       justify-content: center;   
       width: 100%;
       color:white;
       &--current-user {
        color: white;
       }
    }
    &__item { 
     display: flex; 
     align-items: center;  
     justify-content: center;
     font-family: 'Courier', monospace;
     font-weight: bold;
     text-transform: uppercase;  
     width: 33%;
     @include responsive-size(font-size , 30);
    }
}