@import 'common.scss';

.bd-quiz-question-info {
    &__main {       
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        @include responsive-size(border-radius, 20);
        @include responsive-size(padding, 50);
        width: 100%;
        background-color: rgba(0,0,0,0.2);
        @include responsive-size(width, 1222);
        position: relative;
    }

    &__content {
        font-family: 'Avenir';
        color: white;
        @include responsive-size(font-size, 26);
    }
    &__icon {
        position: absolute;
        cursor: pointer;
        @include responsive-size(top, 0);
        @include responsive-size(right, 0);
        @include responsive-size(width, 60);
        @include responsive-size(height, 60);
    }
}