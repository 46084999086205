@import "common.scss";

.bd-quiz {
  &__main {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100vh;
    @include responsive-size(width, 1525);
    @include responsive-size(height, 1145);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include responsive-size(margin-top, 20);
  }

  &__questions-correct {
    font-family: "Fredoka One";
    @include responsive-size(font-size, 70);
    color: #51aabc;
    @include responsive-size(margin-top, 10);
    @include responsive-size(margin-bottom, 10);
  }
}
