@import "common.scss";

.bd-quiz-question-navigator {
  &__main {
    display: flex;
    align-items: center;
    @include responsive-size(margin-top, 30);
  }
  &__pause-icon {
    cursor: pointer;
    @include responsive-size(width, 80);
    @include responsive-size(height, 80);
    path[fill="none"] {
      stroke:  #51AABC;
    }
  }
  &__arrow {
    cursor: pointer;
      @include responsive-size(width, 68);
      @include responsive-size(height, 72);  
      @include responsive-size(margin-left, 20);
      @include responsive-size(margin-right, 20);   
    path[fill="#fff"] {
      fill: #51aabc;
    }
    &--inactive {
      cursor: inherit;
      path[fill="#fff"] {
        fill: #e5e5e5;
      }
    }
  }
}
