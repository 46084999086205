@import "common.scss";

.bd-quizscores {
  &__main {
    display: flex;   
    justify-content: center; 
    align-items: center; 
    @include responsive-size(width, 1525);
    @include responsive-size(height, 1145);
  }

  &__content {
    @include responsive-size(width, 1200); 
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 5%;
    background-color: white;
  }

  &__subcontainer {
    display: flex;
    justify-content: center;
    width: 100%;
    @include responsive-size(height, 600);
    &-left,
    &-right {
      width: 35%;
      background-color: #75AF92;
      @include responsive-size(border-radius, 15);
      &__scores {
        overflow-y: auto;
        @include responsive-size(height, 460);
      }
      &__scores::-webkit-scrollbar {
        width: 0.3em;
        padding: 2px;
        background: #f9e3bf;
      }
      &__scores::-webkit-scrollbar-thumb {
        background: #3d271e;
        height: 30px;
      }
      body::-webkit-scrollbar-track-piece {
        display: none;
      }
    }
    &-left {
      @include responsive-size(margin-right, 45);
    }
    &-right {
      @include responsive-size(margin-left, 45);
    }
  }

  &__header {
    position: relative;
    &-icon {
      @include responsive-size(width, 110);
      @include responsive-size(height, 115);
      position: absolute;
      @include responsive-size(left, -120);
      @include responsive-size(top, -30);
    }
    &-text {
      font-family: "Fredoka One";
      color: white;
      @include responsive-size(font-size, 66);
      user-select: none;
      text-transform: uppercase;
    }
  }
  &__back {
    
    @include responsive-size(width, 300);
    @include responsive-size(right, 20);
    @include responsive-size(bottom, 140);
    border-color: #5a9f6d;
    background-color: white;
    color: #5a9f6d;    
    @include responsive-size(margin, 30);
    @include responsive-size(border-radius, 15);
  }

  &__table-header {
    font-family: "Fredoka One";
    @include responsive-size(font-size, 30);
    color: black;
    text-transform: uppercase;
    user-select: none;
    text-align: center;
    @include responsive-size(margin-top, 20);
    @include responsive-size(margin-bottom, 20);
  }
}
