@import '../../../../common.scss';

.hp-appTopMenu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  .hp-clickable {
    cursor: pointer;
    svg {
      cursor: pointer;
      -webkit-user-select: inherit;
      user-select: inherit;
    }
  }
  &, .hp-subMenu {
    @include responsive-size(width, 234);
  }
  .hp-logo {
    position: absolute;
    z-index: 10;
    &, svg {
      @include responsive-size(height, 82);
    }
    svg { 
      @include responsive-size(width, 230); 
    }
  }

  .hp-subMenu {
    left: 0;
    background-color: black;
    border-bottom-left-radius: 0;

    position: absolute;
    overflow: hidden;
    top: 0;
    z-index: 7;
    @include responsive-size(padding-top, 81);
    & > div {
      display: flex;
      align-items: center;
    }
    -moz-transition: max-height 0.5s;
    -ms-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    -webkit-transition: max-height 0.5s;
    transition: max-height ease 0.5s;
  
    &.hp-subMenuOpen {
      @include responsive-size(max-height, 436); 
    }
    &.hp-subMenuClose {
      max-height: 0;
    }
    @include responsive-size(border-bottom-right-radius, 45);
    &.ks-subMenuClose {
      max-height: 0;
    }
    .hp-appTopMenu-menuApps {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      &, a {
        color: white;
        text-transform: uppercase;
        text-decoration: none;
      }
      font-family: 'Inter';
      @include responsive-size(font-size, 18); 
      > div:first-child {
        @include responsive-size(margin-top, 8); 
      }
      .hp-appTopMenu-menuApps-app {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          opacity: 0.5;
        }
        @include responsive-size(margin-left, 8);
        @include responsive-size(margin-bottom, 8); 
        img {
          @include responsive-size(width, 49); 
          @include responsive-size(height, 49); 
          @include responsive-size(margin-right, 6); 
        }
        &.hp-appTopMenu-menuApps-app-selected {
          span {
            opacity: 1;
          }
        }
      }
      .hp-appTopMenu-menuApps-home {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include responsive-size(margin-left, 12);
        @include responsive-size(width, 206);
        @include responsive-size(padding-top, 11); 
        @include responsive-size(margin-bottom, 21); 
        border-top: 1px solid white;
        svg {
          @include responsive-size(width, 43.66); 
          @include responsive-size(height, 38.2); 
          @include responsive-size(margin-right, 6); 
        }
      }
    }
  }

}