@import "common.scss";

.bd-quiz-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-style: solid; 
  @include responsive-size(border-radius, 50);    
  @include responsive-size(border-width, 3);
  @include responsive-size(height, 83);

  font-family: "Fredoka One";
  @include responsive-size(font-size, 32);
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  user-select: none;

  &--blue {
    border-color: white;
    background-color: #51aabc;
    color: white;
  }
  &--grey {
    border-color: white;
    background-color: #c3c3c3;
    color: white;
  }
  &--brown {
    border-color: white;
    background-color: #8B563A;
    color: white;
  }
  &--orange {
    border-color: #CE6F3D;
    background-color: white;
    color: #A7420D;
  }
  &--orange-dark {
    border-color: #CE6F3D;
    background-color: #EAC676;
    color: #A7420D;
  }
  
  &--transparent {
    color: #fff;
  }
  &--white {
    color: black;
  }
  &--white-scores {
    border-color: white;
    color: white;
  }
  &-font--big {
    @include responsive-size(font-size, 38);
  }
  &-font--small {
    @include responsive-size(font-size, 27);
  }
  &--shadow {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
}
