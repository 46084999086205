@import "common.scss";

.bd-quiz-lost-modal {
  &__main {
    z-index: 10;
  }

  &__container {   
    @include responsive-size(width, 520);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  &__icon {
  }

  &__text {
    font-family: "Fredoka One";
    color: #51aabc;
    @include responsive-size(font-size, 90);
  }

  &__buttons {   
    display: flex;
    justify-content: center;
    width: 100%;
    @include responsive-size(margin-top, 35);
    @include responsive-size(margin-bottom, 35);
    &-rejouer,
    &-accueil { 
        width: 50%; 
    }
    &-rejouer {
        @include responsive-size(margin-right, 5);
    }
    &-accueil {
        @include responsive-size(margin-left, 5);
    }
  }
  &__button-scores {
    &__container {
        width: 100%;
        position: relative;
    }
    &__icon {
        position: absolute;
        top: -10px;
        left:0;
        @include responsive-size(width, 95);
        @include responsive-size(height, 105);
    }
    &__button { 
        width: 100%;    
      //@include responsive-size(width, 548);
      //@include responsive-size(height, 83);
    }
  }
}
