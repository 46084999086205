@import "common.scss";

.bd-quiz-goScreen {
  &__info {
    position: absolute;
    @include responsive-size(width, 392);
    @include responsive-size(height, 290);
    @include responsive-size(left, 320);
    @include responsive-size(top, 310);
  }
  &__loading {
     font-family: "Fredoka One";
     color: white;
     user-select: none;
     @include responsive-size(font-size, 45);
  }

  &__go {
    @include responsive-size(width, 180);
    text-transform: uppercase;
    .bd-quiz-button {
        @include responsive-size(font-size, 56);
        margin: 0;
    }
  }
}
