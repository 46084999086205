@import "common.scss";

.bd-quiz-pause-modal {

  &__main {
    z-index: 10;
  }  
  
  &__pause {
    display: flex;    
    flex-direction: column;
    align-items:center;
    justify-content:space-evenly;
  }

  &__pause-icon {
    @include responsive-size(width, 200);
    @include responsive-size(height, 200);
    @include responsive-size( margin-bottom, 15);
  }

  &__pause-text {
    font-family: "Fredoka One";
    color: #51aabc;
    @include responsive-size(font-size, 70);
    @include responsive-size( margin-bottom, 40);
  }

  &__pause-buttons {
    width: 90%;    
    &-reprendre{
        @include responsive-size( margin-bottom, 10);
    }
  }
}
