@import "common.scss";

.bd-quiz-won-modal {
  &__main {
    z-index: 10;
  }

  &__container {
    @include responsive-size(width, 600);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  
  &__header {
    font-family: "Fredoka One";
    color: #51aabc;
    @include responsive-size(font-size, 90);
    user-select: none;
  }

  &__position-score {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include responsive-size(margin-top, 25);
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include responsive-size(width, 314);
      @include responsive-size(height, 174);
      @include responsive-size(border-radius, 30);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      &:first-child {
        @include responsive-size(margin-right, 30);
      }
      .bd-quiz-won-modal__score {
        line-height: 1.2;
        @include responsive-size(font-size, 71);
        .css-0 {
          > div {
            @include responsive-size(width, 20);
            @include responsive-size(height, 20);
            @include responsive-size(margin, 3);
          }
        }
      }
    }
    label {
      font-family: 'Fredoka One';
      @include responsive-size(font-size, 30);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    label {
      font-family: "Fredoka One";
      user-select: none;
      @include responsive-size(font-size, 38);
      color: black;
    }
    @include responsive-size(margin-top, 50);

    &-arrow {
      position: absolute;
      @include responsive-size(left, -30);
      @include responsive-size(top, 100);
      path[fill="#fff"] {
        fill: #c3c3c3;
      }
      @include responsive-size(width, 60);
      @include responsive-size(height, 60);
    }
    &-submit {
       cursor: pointer;
    }
    &-submit, &-tick {
      position: absolute;
      @include responsive-size(right, 15);
      @include responsive-size(top, 90);
      @include responsive-size(width, 70);
      @include responsive-size(height, 70);
      z-index:2;
    }
  }

  &__input-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsive-size(width, 500);
    @include responsive-size(height, 101);
    @include responsive-size(margin-top, 30);

    input {
      font-family: "Fredoka One";
      @include responsive-size(font-size, 90); 
      @include responsive-size(border-width, 5);
      @include responsive-size(border-radius, 20);
      color: #C3C3C3;
      border-style: solid;
      border-color: #C3C3C3;      
      display: inline-block;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &__sub-header {
    font-family: "Fredoka One";
    color: black;
    @include responsive-size(font-size, 45);
    @include responsive-size(line-height, 45);
    @include responsive-size(margin-top, 35);
    @include responsive-size(margin-bottom, 5);
    user-select: none;
  }

  &__score {
    font-family: "Fredoka One";
    color: black;
    @include responsive-size(font-size, 100);
    @include responsive-size(line-height, 100);
  }

  &__buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    @include responsive-size(margin-top, 70);
    @include responsive-size(margin-bottom, 35);
    &-rejouer,
    &-accueil {
      width: 50%;
    }
    &-rejouer {
      @include responsive-size(margin-right, 5);
    }
    &-accueil {
      @include responsive-size(margin-left, 5);
    }
  }
  &__button-scores {
    &__container {
      width: 100%;
      position: relative;
    }
    &__icon {
      position: absolute;     
      left: 0;
      @include responsive-size(width, 95);
      @include responsive-size(height, 105);
      @include responsive-size(top, -10);
      @include responsive-size(height, 105);
    }
    &__button {
      width: 100%;      
    }
  }
}
