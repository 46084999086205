@import "../../../common.scss";

.bd-divider-v-0 {
    @include responsive-size(height, 0);
}
.bd-divider-v-20 {
  @include responsive-size(height, 20);
}
.bd-divider-v-30 {
  @include responsive-size(height, 30);
}
.bd-divider-v-40 {
  @include responsive-size(height, 40);
}
.bd-divider-h-10 {
  @include responsive-size(width, 10);
}
.bd-divider-h-0 {
    @include responsive-size(width, 0);
}
.bd-divider-h-20 {
  @include responsive-size(width, 20);
}
.bd-divider-h-30 {
  @include responsive-size(width, 30);
}
.bd-divider-h-40 {
  @include responsive-size(width, 40);
}

