@import "common.scss";

.bd-quiz-question-choices {
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center; 
    @include responsive-size(margin, 15); 
    
  }
  &-double {
    flex-direction: row;    
  }
  &-multi {
    flex-direction: column;   
  }
}
