@import "common.scss";

.bd-quiz-boat-animation {
    &__main {
        position:absolute;       
        bottom:0;
        left:0;
        @include responsive-size(width, 1525);
        @include responsive-size(height, 280);
    }
    &__container {
        position:relative;       
        height: 100vh;
    }

    &__boat-computer {
        position:absolute;
        top:0;
        left:0;
        @include responsive-size(width, 324);
        @include responsive-size(height, 189);
        z-index:1;
    }

    &__boat-player {
        position:absolute;
        @include responsive-size(bottom, 20);
        @include responsive-size(left, 5);
        @include responsive-size(width, 324);
        @include responsive-size(height, 189);
        z-index:5;
    }

    @mixin wavecolorSunset {
         filter: brightness(68%) hue-rotate(25deg) contrast(150%) saturate(60%);
    }
    @mixin wavecolorNight {
        filter: brightness(75%) hue-rotate(18deg) contrast(120%);
   }

    &__wave3{
        position:absolute;
        @include responsive-size(bottom, 20);
        @include responsive-size(left, 0);
        @include responsive-size(width, 3050);
        @include responsive-size(height, 102);
        z-index:2;
        &--sunset {
           @include wavecolorSunset();
        }
        &--night {
            @include wavecolorNight();
         }
    }
    &__wave2{
        position:absolute;
        @include responsive-size(bottom, 30);
        left:0;
        @include responsive-size(width, 3050);
        @include responsive-size(height, 59);
        z-index:4;
        &--sunset {
            @include wavecolorSunset();
        }
        &--night {
            @include wavecolorNight();
         }
    }
    &__wave1{
        position:absolute;
        bottom:0;
        left:0;
        @include responsive-size(width, 3050);
        @include responsive-size(height, 59);
        z-index:6;

        &--sunset {
            @include wavecolorSunset();
        }
        &--night {
            @include wavecolorNight();
         }
    }
    &__flag{
        position:absolute;
        @include responsive-size(bottom, 55);
        @include responsive-size(left, 320);
        @include responsive-size(width, 48);
        @include responsive-size(height, 82);
        z-index:3;
    }
}