@import "common.scss";

.bd-quiz-question-container {
  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    @include responsive-size(border-radius, 50);
    @include responsive-size(padding, 40);
    @include responsive-size(padding-bottom, 40);
    @include responsive-size(width, 1222);
    @include responsive-size(height, 310); 
    position:relative;
  }

  &__logo {   
    @include responsive-size(width, 104);
    @include responsive-size(height, 87); 
  }

  &__icon {
    position: absolute;
    cursor: pointer;
    @include responsive-size(width, 90);
    @include responsive-size(height, 90); 
    right: 0;
    top: 0;
  }

  &__question-text-container  {
    display: flex;
    align-items: center;
    @include responsive-size(height, 150);
    @include responsive-size(padding-bottom, 20);    
  }

  &__question-text {
    font-family: "Fredoka One";
    text-align: center;
    @include responsive-size(font-size, 31);
  }
  &__navigator {
   position:absolute;
   @include responsive-size(bottom, 30);
  }
}
