@import "../../common.scss";

.ms-home-icon {
	width: responsiveSizeLegacyToV2(319.39);
	height: responsiveSizeLegacyToV2(222.26);
	flex: 1;
	display: flex;
	svg {
		width: 100%;
		height: 100%;
	}
}