@import "common.scss";

.bd-quiz-scoreitem {
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: left;
    @include responsive-size(width, 350);
    @include responsive-size(margin-top, 20);
    @include responsive-size(margin-bottom, 5);

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #75af92;
      @include responsive-size(border-radius, 10);
      @include responsive-size(padding, 5);
      svg {
        @include responsive-size(width, 40);
        @include responsive-size(height, 40);
      }
    }

    &-text {
      font-family: "Avenir";
      font-weight: bold;
      text-transform: uppercase;
      @include responsive-size(font-size, 25);
      color: #75af92;
      @include responsive-size(margin-left, 15);
    }
  }
  &__scores_item {
    @include responsive-size(width, 400);
    @include responsive-size(margin-left, -35);
  }
}
