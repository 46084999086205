@import 'common.scss';

.bd-quiz-overlay {
    position:relative;
  &__main {
    display: flex; 
    align-items: center;   
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size:contain;	
    background-image: url("../../../assets/quiz/overlay.svg");
    @include responsive-size(width, 1525);
    @include responsive-size(height, 1145);
  }
  &__content {
      
  }
}
