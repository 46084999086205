@import "./common.scss";

html,
body {
	overflow: hidden;
	position: relative;
	height: fit-content;
}
#bdouinQuiz {
	width: fit-content;
	height: fit-content;
	margin: auto;
	overflow: hidden;
	.qz-emoji2 {
		@include responsive-size(font-size, 100);
		line-height: 1;
		img {
			@include responsive-size(height, 100);
			@include responsive-size(width, 100);
		}
	}
}

#noop {
  height: 0;
  display: none;
}

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button.mainBtn {
	font-family: "Fredoka One";
	background-color: #51aabc;
	&:focus {
		outline: 0;
	}
	cursor: pointer;
	@include responsive-size(border-radius, 30);
	@include responsive-size(padding-left, 16);
	@include responsive-size(padding-right, 16);
	@include responsive-size(padding-top, 6);
	@include responsive-size(padding-bottom, 6);
	border: none;
	color: white;
	&:disabled {
		opacity: 0.4;
	}
}

svg {
	cursor: default;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	.ms-clickable & {
		cursor: pointer;
	}
}

.ms-clickable {
	cursor: pointer;
}

hr {
    border: solid 1px #c3c3c3;
	@include responsive-size(margin, 20);
	opacity: 0.3;
    width: 90%;
  }