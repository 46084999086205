@import "common.scss";

.bd-quiz-scorepreview {
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    @include responsive-size(width, 400);
    @include responsive-size(height, 1080);
    @include responsive-size(border-radius, 50);
  }
  &__hr {
    width: 100%;
    @include responsive-size(border-width, 1.5);
    @include responsive-size(margin-bottom, 0);
  }
  &__top-header {
    font-family: "Fredoka One";
    @include responsive-size(font-size, 24);
    @include responsive-size(padding-top, 25);
    text-transform: uppercase;
    color: white;
    text-align: center;
  }
  &__all-scores-button {
    @include responsive-size(border-radius, 10);
    @include responsive-size(font-size, 20);
    @include responsive-size(width, 290);
    @include responsive-size(height, 80);
    @include responsive-size(margin-top, 18);
  }
}
