@import "common.scss";

.bd-quiz-themes {
  &__main {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;

    &--home {
      background-image: url("~assets/quiz/backgrounds/quiz-home-background.svg");
    }

    &--new-home {
      background-image: url("~assets/quiz/backgrounds/quiz-new-home-background.svg");      
    }

    &--new-home--faded {
      background-image: url("~assets/quiz/backgrounds/quiz-new-home-background.svg");
      opacity: 0.4;
    }

    &--entrainement {
      background-image: url("~assets/quiz/backgrounds/quiz-entrainement-background.svg");
    }

    &--scores {
      background-image: url("~assets/quiz/backgrounds/quiz-scores-background.svg");
    }

    &--sunset {
      background-image: url("~assets/quiz/themes/theme-sunset-background.svg");
    }

    &--day {
      background-image: url("~assets/quiz/themes/theme-day-background.svg");
    }

    &--sunset .bd-quiz-themes__clouds-small, &--sunset .bd-quiz-themes__clouds-big {
      path[fill="#ffffff"] {
				fill: #ffc7aa;
			}
    }

    &--night {
      background-image: url("~assets/quiz/themes/theme-night-background.svg");
    }

    &--night .bd-quiz-themes__clouds-small {
      z-index: -2;
    }
    
  }

  &__back-scroll {
    position: absolute;
    @include responsive-size(width, 3992); 
    @include responsive-size(height, 184); 
    @include responsive-size(bottom, 180);        
  }

  &__clouds-big,
  &__clouds-small {
    @include responsive-size(width, 3050);
    @include responsive-size(height, 120);
    position: absolute;
  }
  &__clouds-big {  
    @include responsive-size(bottom, 200);
  }
  &__clouds-small {
    opacity: 0.6;
    @include responsive-size(bottom, 200);
  }
}
