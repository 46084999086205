@import "../../../../common.scss";

.bd-quiz-themeselector {
  &__main {
  }
  &__previews-container {
    display: flex;
  }

  &__preview {
    @include responsive-size(width, 190);
    //@include responsive-size(height, 208);
    height: auto;
    @include responsive-size(border-radius, 15);
    @include responsive-size(margin, 20);
    opacity: 0.3;
    user-select: none;
    cursor: pointer;

    &--selected {
      opacity: 1;
    }
  }
}
