@import "../../../../common.scss";

.bd-quiz-categoryselector {
  display: flex;
  align-items: center;
  justify-content: center;
  @include responsive-size(margin-bottom, 10);
  @include responsive-size(width, 960);
  height: auto;

  &__arrow {
    @include responsive-size(margin-top, 0);
    @include responsive-size(margin-right, 15);
    @include responsive-size(margin-left, 15);
    @include responsive-size(margin-bottom, 10);
    user-select: none;
    cursor: pointer;
    @include responsive-size(width, 75);
    @include responsive-size(height, 75);
    &--inactive {
      cursor: default;
      path[fill="#fff"] {
        fill: #e5e5e5;
      }
    }
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
    @include responsive-size(width, 850);
  }

  &__category {
    color: white;
    font-family: "Fredoka One";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    background-color: #75af92;
    opacity: 0.3;
    margin: 0 10px 0;
    @include responsive-size(border-radius, 20);
    @include responsive-size(width, 160);
    @include responsive-size(height, 160);
    cursor: pointer;
    &:not(:last-child) {
      @include responsive-size(margin-right, 20);
    }
    &.selected {
      background-color: #75af92;
      opacity: 1;
    }
    &.disabled {
      cursor: not-allowed;
    }
    .overlay {
      display: none;
    }
    &.blocked {
      .overlay {
        display: block;
        position: absolute;
        z-index: 2;
        top: 3px;
        left: 3px;
        width: 100%;
        height: 100%;
        svg {
          position: absolute;
          @include responsive-size(width, 25);
          @include responsive-size(height, 29);
          @include responsive-size(right, 15);
          @include responsive-size(top, 3);
        }
      }

      &.selected {
        .overlay svg path {
          fill: black;
        }
      }
    }
    &-subject-icon {
      pointer-events: none;
    }

    &-subject-icon svg {
      @include responsive-size(width, 80);
      @include responsive-size(height, 80);
    }
    &-label {
      text-align: center;
      @include responsive-size(font-size, 20);
    }
  }
}
